import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CartManagerService } from '../cart-manager.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public form: FormGroup;
  public errorMessage: String;

  constructor(private fb: FormBuilder,
    private cartService: CartManagerService) { }



  ngOnInit() {
    this.createForm();
  }

  public createForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email, Validators.minLength(1)]],
      confirmEmail: [null, [Validators.required, Validators.email, Validators.minLength(1), this.confirmEmailValidator]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(8), this.confirmPasswordValidator]],
      cognome: [null, [Validators.required, Validators.minLength(1)]],
      nome: [null, [Validators.required, Validators.minLength(1)]],
      ragioneSociale1: [null, [Validators.required, Validators.minLength(1)]],
      partitaIVA: [null, [Validators.required, Validators.minLength(1)]],
      codiceFiscale: [null, [Validators.required, Validators.minLength(1)]],
      indirizzo: [null, [Validators.required, Validators.minLength(1)]],
      cap: [null, [Validators.required, Validators.minLength(1)]],
      localita: [null, [Validators.required, Validators.minLength(1)]],
      provincia: [null, [Validators.required, Validators.minLength(1)]],
      haIndirizzoSpedizione: [false],
      indirizzoSpedizione: [null],
      capSpedizione: [null],
      localitaSpedizione: [null],
      provinciaSpedizione: [null],
      iban: [null, [Validators.required, Validators.minLength(1)]],
      pec: [null, [this.requiredPecValidator]],
      cu: [null, [this.requiredCuValidator]]
    });

    // this.form.patchValue({
    //   email: 'pierino69@gmail.com',
    //   confirmEmail: 'pierino69@gmail.com',
    //   password: 'p312dekdpel',
    //   confirmPassword: 'p312dekdpel',
    //   cognome: 'Dal Maso',
    //   nome: 'Pierangelo',
    //   ragioneSociale1: 'Newel s.r.l.',
    //   partitaIVA: '03426260273',
    //   codiceFiscale: '03426262073',
    //   indirizzo: 'Via Castellantico 39',
    //   cap: '30035',
    //   localita: 'Mirano',
    //   provincia: 'VE',
    //   haIndirizzoSpedizione: false,
    //   iban: '213123123121',
    //   pec: 'pec@pec.newelsys.it',
    //   cu: 'AX324SF'
    // })

    this.form.controls['haIndirizzoSpedizione'].valueChanges.subscribe(
      x => {
        if (x) {
          this.form.get('indirizzoSpedizione').setValidators([Validators.required, Validators.minLength(1)]);
          this.form.get('localitaSpedizione').setValidators([Validators.required, Validators.minLength(1)]);
          this.form.get('capSpedizione').setValidators([Validators.required, Validators.minLength(1)]);
          this.form.get('provinciaSpedizione').setValidators([Validators.required, Validators.minLength(1)]);          
        }
        else {
          this.form.get('indirizzoSpedizione').clearValidators();
          this.form.get('localitaSpedizione').clearValidators();
          this.form.get('capSpedizione').clearValidators();
          this.form.get('provinciaSpedizione').clearValidators();
        }
        this.form.get('indirizzoSpedizione').updateValueAndValidity();
        this.form.get('localitaSpedizione').updateValueAndValidity();
        this.form.get('capSpedizione').updateValueAndValidity();
        this.form.get('provinciaSpedizione').updateValueAndValidity();
      }
    );
  }

  requiredPecValidator(c: FormControl): {[key: string]: any} | null {
    if (c.parent && (!c.parent.get("cu").value || c.parent.get("cu").value.length == 0)) {
      if (!c || !c.value || c.value.length == 0) {
        return {'required': true}
      }
    }
    return null;
  }

  requiredCuValidator(c: FormControl): {[key: string]: any} | null {
    if (c.parent && (!c.parent.get("pec").value || c.parent.get("pec").value.length == 0)) {
      if (!c || !c.value || c.value.length == 0) {
        return {'required': true}
      }
    }
    return null;
  }

  requiredIfIndSpedValidator(c: FormControl): {[key: string]: any} | null {
    if (c.parent && c.parent.get("haIndirizzoSpedizione").value == true) {
      if (!c || !c.value || c.value.length == 0) {
        return {'required': true}
      }
    }
    return null;
  }

  confirmEmailValidator(c: FormControl): {[key: string]: any} | null
  {
    if (c.parent && c.parent.get('email').value) {
      if (!c || !c.value || c.value != c.parent.get('email').value) {
        return {'dontMatch': true}
      }
    }
    return null;
  }

  confirmPasswordValidator(c: FormControl): {[key: string]: any} | null
  {
    if (c.parent && c.parent.get('password').value) {
      if (!c || !c.value || c.value != c.parent.get('password').value) {
        return {'dontMatch': true}
      }
    }
    return null;
  }


  public registrati() {
    this.errorMessage = '';
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.cartService.checkNewCliente(this.form.value).subscribe({ 
        next: x => {
          this.cartService.registerCustomer(this.form.value);
        },
        error: x => {
          this.errorMessage = x.error;
        }
      })

    }

  }

  public get haIndirizzoSpedizione() {
    return this.form.value.haIndirizzoSpedizione;
  }

}
