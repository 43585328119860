import { Type } from 'class-transformer';


export class Category {
    key: string;
    name: string;
    subcategories: Category[];
    products: Product[];
}

export class Product {
    id: number;
    code: string;
    name: string;
    description: string;
    imageUrl: string;
    price: number;
    disc1: number;
    disc2: number;
    disc3: number;
    netPrice: number;
    quantita: number = 1;
    ohs: number;
}


export class Cart {
    customer: Organizzazione;
    destination: Indirizzo;
    notes: string;
    dueDate: Date;

    @Type(() => CartRow)
    rows: CartRow[] = [];

    id: number;
    year: string;
    orderNumber: string;
    registry: string;

    totalAmount: number;
}

export class CartRow {
    id: number;

    product: Product;
    quantity: number;
    disc1: number;
    disc2: number;
    disc3: number;
    netPrice: number;

    public printDiscounts(): string {
        var s = '';

        if (this.disc1 && this.disc1 != 0) {
            s += this.disc1 + '%'
        }

        if (this.disc2 && this.disc2 != 0) {
            if (s != '') {
                s += ' + ';
            }
            s += this.disc2 + '%'
        }

        if (this.disc3 && this.disc3 != 0) {
            if (s != '') {
                s += ' + ';
            }
            s += this.disc3 + '%'
        }

        return s;
    }
}

export class CondizioneDiPagamento {
    id: number;
    codice: string;
    descrizione: string;
}

export class Organizzazione {
    codice: string;
    id: number;
    ragioneSociale1: string;
    indirizzi: Indirizzo[];
    condizioneDiPagamento: CondizioneDiPagamento;
}

export class Indirizzo {
    ragioneSociale2: string;
    indirizzo1: string;
    indirizzo2: string;
    localita: string;
    cap: string;
    provincia: Provincia;
}

export class Provincia {
    id: number;
    codice: string;
    descrizione: string;
}

export class DashboardInfoResponse {
    @Type(() => Cart)
    pendingOrders: Cart[];

    @Type(() => Cart)
    sentOrders: Cart[];
  }
  
  