import { Component, OnInit, Input, Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { Product, Category } from '../app.model';
import { MatGridTile } from '@angular/material/grid-list';
import { CartManagerService } from '../cart-manager.service';

@Component({
  selector: 'app-categories-selector',
  templateUrl: './categories-selector.component.html',
  styleUrls: ['./categories-selector.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class CategoriesSelectorComponent implements OnInit {

  constructor(public cartManager: CartManagerService) {

  }

  ngOnInit() {
//      if (this.products) {
//          this.selectedProduct = this.products[0];
//      }
  }

  onChangeSelection(value: number): void {
    this.cartManager.selectedCategoryIndex = value;
    this.cartManager.selectedCategory = this.cartManager.categories[this.cartManager.selectedCategoryIndex];
    this.cartManager.selectedProduct = null;
  }

  selectProduct(product: Product): void {
      this.cartManager.selectedProduct = product;
  }
  
  public closeDetails(): void {
      this.cartManager.selectedProduct = null;
  }

  public quantitaPlus(product: Product) {
    if (product.quantita) {
      product.quantita += 1;
    }
    else {
      product.quantita = 1;
    }
    this.cartManager.updateTotals();
  }

  public quantitaMinus(product: Product) {
    if (product.quantita && product.quantita > 1) {
      product.quantita -= 1;
    }
    else {
      product.quantita = 1;
    }

  }

  public addToCart(product: Product) {
    if (product.quantita && product.quantita >= 1) {
      this.cartManager.addToCart(product, product.quantita);
    }
  }

  public availability(product: Product): string {
    if (product.ohs > 10) {
      return "high";
    }
    else if (product.ohs > 0) {
      return "low";
    }
    else {
      return "no";
    }
  }
}
