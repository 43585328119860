import { Component, OnInit } from '@angular/core';
import { CartManagerService } from '../cart-manager.service';

@Component({
  selector: 'app-catalog-page',
  templateUrl: './catalog-page.component.html',
  styleUrls: ['./catalog-page.component.css']
})
export class CatalogPageComponent implements OnInit {

  constructor(public cartManager: CartManagerService) { }

  ngOnInit() {
    this.cartManager.loadCatalog();
  }

}
