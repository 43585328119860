import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-cart-duplicate-product-dlg',
  templateUrl: './cart-duplicate-product-dlg.component.html',
  styleUrls: ['./cart-duplicate-product-dlg.component.css']
})
export class CartDuplicateProductDlgComponent implements OnInit {

  public dialogText: string;

  yes = 'YES';
  no = 'NO';
  cancel = 'CANCEL';

  constructor(public dialogRef: MatDialogRef<CartDuplicateProductDlgComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  addToExisting() {
    this.dialogRef.close(this.yes);
  }

  addNew() {
    this.dialogRef.close(this.no);
  }

  ignore() {
    this.dialogRef.close(this.cancel);
  }

}
