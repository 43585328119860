import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { CategoriesSelectorComponent } from './categories-selector/categories-selector.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatSpinner,
    MAT_DATE_LOCALE
  } from '@angular/material';
import { CartHeaderComponent } from './cart-header/cart-header.component';
import { CatalogPageComponent } from './catalog-page/catalog-page.component';

import { AppRoutingModule } from './app-routing.module';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component'
import { CartPageComponent } from './cart-page/cart-page.component';
import { LoginComponent } from './login/login.component';
import { OrderHeaderPageComponent } from './order-header-page/order-header-page.component';
import { CartDuplicateProductDlgComponent } from './cart-duplicate-product-dlg/cart-duplicate-product-dlg.component';
import { RegisterComponent } from './register/register.component';
import { RegisteredComponent } from './registered/registered.component';
import { RegisterExistingComponent } from './register-existing/register-existing.component';
@NgModule({
  declarations: [
    AppComponent,
    ProductDetailsComponent,
    CategoriesSelectorComponent,
    CartHeaderComponent,
    CatalogPageComponent,
    DashboardPageComponent,
    CartPageComponent,
    LoginComponent,
    OrderHeaderPageComponent,
    CartDuplicateProductDlgComponent,
    RegisterComponent,
    RegisteredComponent,
    RegisterExistingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatGridListModule,
    HttpClientModule,
    MatTabsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatBadgeModule,
    FormsModule,
    AppRoutingModule,
    MatMenuModule,
    MatTableModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatDialogModule,
    MatSlideToggleModule
  ],
  exports: [
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    CategoriesSelectorComponent
  ],
  providers: [    
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
  ],
  bootstrap: [AppComponent],
  entryComponents: [CartDuplicateProductDlgComponent]
})
export class AppModule { }
