import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CartManagerService } from '../cart-manager.service';

@Component({
  selector: 'app-register-existing',
  templateUrl: './register-existing.component.html',
  styleUrls: ['./register-existing.component.css']
})
export class RegisterExistingComponent implements OnInit {

    public form: FormGroup;
    public errorMessage: String;
  
    constructor(private fb: FormBuilder,
      private cartService: CartManagerService) { }
  
  
  
    ngOnInit() {
      this.createForm();
    }
  
    public createForm() {
      this.form = this.fb.group({
        email: [null, [Validators.required, Validators.email, Validators.minLength(1)]],
        confirmEmail: [null, [Validators.required, Validators.email, Validators.minLength(1), this.confirmEmailValidator]],
        password: [null, [Validators.required, Validators.minLength(8)]],
        confirmPassword: [null, [Validators.required, Validators.minLength(8), this.confirmPasswordValidator]],
        cognome: [null, [Validators.required, Validators.minLength(1)]],
        nome: [null, [Validators.required, Validators.minLength(1)]],
        codiceCliente: [null, [Validators.required, Validators.minLength(1)]],
        partitaIVA: [null, [Validators.required, Validators.minLength(1)]]
      });
  
    //   this.form.patchValue({
    //     email: 'pierino69@gmail.com',
    //     confirmEmail: 'pierino69@gmail.com',
    //     password: 'p312dekdpel',
    //     confirmPassword: 'p312dekdpel',
    //     cognome: 'Dal Maso',
    //     nome: 'Pierangelo',
    //     partitaIVA: '03426260273'
    //   });
    }
  
  
  
    confirmEmailValidator(c: FormControl): {[key: string]: any} | null
    {
      if (c.parent && c.parent.get('email').value) {
        if (!c || !c.value || c.value != c.parent.get('email').value) {
          return {'dontMatch': true}
        }
      }
      return null;
    }
  
    confirmPasswordValidator(c: FormControl): {[key: string]: any} | null
    {
      if (c.parent && c.parent.get('password').value) {
        if (!c || !c.value || c.value != c.parent.get('password').value) {
          return {'dontMatch': true}
        }
      }
      return null;
    }
  
  
    public registrati() {
      this.errorMessage = '';
      this.form.updateValueAndValidity();
  
      if (this.form.valid) {
        this.cartService.checkClienteEsistente(this.form.value).subscribe({ 
          next: x => {
            this.cartService.registerExistingCustomer(this.form.value);
          },
          error: x => {
            this.errorMessage = x.error;
          }
        })
  
      }
  
    }
  
}
  