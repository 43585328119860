import { Component, OnInit } from '@angular/core';
import { CartManagerService } from '../cart-manager.service';
import { Product, Category, CartRow } from '../app.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.css']
})
export class CartPageComponent implements OnInit {

  displayedColumns: string[] = ['productImage', 'productName', 'quantity', 'unitPrice', 'disc1', 'netPrice', 'actions'];
  displayedColumnsSummary: string[] = ['productCode', 'productName', 'quantity', 'unitPrice', 'disc1', 'netPrice', 'amount'];

  constructor( public cartManager: CartManagerService,
    private router: Router) { }

  ngOnInit() {
  }

  quantitaPlus(row: CartRow): void {
    row.quantity ++;
  }

  quantitaMinus(row: CartRow): void {
    if (row.quantity >= 1) {
      row.quantity --;
    }
  }

  returnToCatalog(empty: boolean): void {
    if (empty) {
      this.cartManager.cart.rows.splice(0);
      this.router.navigate(['order-header']);
    }
    else {
      this.router.navigateByUrl("/catalog");
    }

  }

  updatePrice(row: CartRow): void {
    var price = row.product.price;

    price = Math.round(price * 100);

    price = Math.round(price * (100 - row.disc1) / 100);
    price = Math.round(price * (100 - row.disc2) / 100);

    price = price / 100.0;

    row.netPrice = price;

    console.log("new price = " + price);
  }

  deleteRow(row: CartRow): void {
    if (confirm('Sei sicuro di voler cancellare?')) {
      var i = this.cartManager.cart.rows.indexOf(row);
      if (i >= 0) {
        this.cartManager.cart.rows.splice(i, 1);
      }
      this.cartManager.createOrUpdateOrder();
    }
  }

  importa(): void {
    alert("Cliccato su importa");

    let righe = [
      "COA90A25:1",
      "COA91A25:1",
      "COA92A25:1",
      "BR802A25:1",
      "BR803A25:1",
      "OR594A25:1",
      "OR595A25:1",
      "OR596A02:1",
      "COA32A19:",
      "COA33A19:",
      "CO235A18:",
      "COB23A11:",
      "COB24A11:",
      "BR822A11:",
      "OR612A11:",
      "COA50A08:",
      "COA52A08:",
      "BR768A08:",
      "OR567A08:",
      "OR559A08:",
      "COA76A06:",
      "COA76A08:",
      "COA76A14:",
      "COA76A36:",
      "COA78A06:",
      "COA78A08:",
      "COA78A14:",
      "COA78A36:",
      "BR797A06:",
      "BR797A08:",
      "BR797A14:",
      "BR797A36:",
      "OR584A06:",
      "OR584A08:",
      "OR584A14:",
      "OR584A36:",
      "COA76A11:",
      "COA78A11:",
      "BR797A11:",
      "BR842A11:",
      "OR584A11:",
      "CO016A01:1",
      "CO016A05:1",
      "CO016A07:1",
      "CO016A10:1",
      "CO016A15:1",
      "CO016A16:1",
      "CO016A27:1",
      "BR297A01:1",
      "BR297A05:1",
      "BR297A07:1",
      "BR297A10:1",
      "BR297A15:1",
      "BR297A16:1",
      "BR297A27:1",
      "OR164A01:1",
      "OR164A05:1",
      "OR164A07:1",
      "OR164A10:1",
      "OR164A15:1",
      "OR164A27:1",
      "CO016A03:1",
      "CO016A08:1",
      "BR297A03:1",
      "BR297A08:1",
      "OR164A03:1",
      "OR164A08:1",
      "COA79A04:",
      "COA79A07:",
      "COA80A04:",
      "COA80A07:",
      "COA81A04:",
      "COA81A07:",
      "BR798A04:",
      "BR798A07:",
      "OR585A04:",
      "OR585A07:",
      "OR586A04:",
      "OR586A07:",
      "CO204A00:",
      "BR263A00:",
      "CO938A11:",
      "CO938A12:",
      "CO939A11:",
      "CO939A12:",
      "BR706A11:",
      "BR706A12:",
      "BR707A11:",
      "BR707A12:",
      "OR507A11:",
      "OR507A12:",
      "COB03A11:",
      "COB04A11:",
      "COB05A11:",
      "BR814A11:",
      "OR602A11:",
      "OR603A11:",
      "COB03A06:",
      "COB03A35:",
      "COB04A06:",
      "COB04A35:",
      "COB05A06:",
      "COB05A35:",
      "BR814A06:",
      "BR814A35:",
      "OR602A06:",
      "OR602A35:",
      "OR603A06:",
      "OR603A35:",
      "CO886A14:",
      "CO889A14:",
      "BR678A14:",
      "OR476A14:",
      "AN182A14:",
      "COA25A01:",
      "COA25A11:",
      "COA25A14:",
      "COA25A59:",
      "COA27A01:",
      "COA27A11:",
      "COA27A14:",
      "COA27A59:",
      "COA28A01:",
      "COA28A11:",
      "COA28A14:",
      "COA28A59:",
      "AN198A01:",
      "AN198A11:",
      "AN198A14:",
      "AN198A59:",
      "CO974A01:",
      "CO974A11:",
      "CO974A14:",
      "CO974A59:",
      "BR753A01:",
      "BR753A11:",
      "BR753A14:",
      "BR753A59:",
      "BR755A01:",
      "BR755A11:",
      "BR755A14:",
      "BR755A59:",
      "OR522A01:",
      "OR522A11:",
      "OR522A14:",
      "OR522A59:",
      "OR538A01:",
      "OR538A11:",
      "OR538A14:",
      "OR538A59:",
      "CO991A03:",
      "CO991A15:",
      "CO994A03:",
      "CO994A15:",
      "BR731A03:",
      "BR731A15:",
      "OR532A03:",
      "OR532A15:",
      "AN197A03:",
      "AN197A15:",
      "COA60A07:1",
      "COA60A32:1",
      "COA61A07:1",
      "COA61A32:1",
      "BR775A07:1",
      "BR775A32:1",
      "OR565A07:1",
      "OR565A32:1",
      "COB61A02:1",
      "COB61A11:1",
      "COB61A14:1",
      "BR844A02:1",
      "BR844A11:1",
      "BR844A14:1",
      "OR627A02:1",
      "OR627A11:1",
      "OR627A14:1",
      "COB64A05:1",
      "COB64A07:1",
      "COB64A57:1",
      "BR847A05:1",
      "BR847A07:1",
      "BR847A57:1",
      "OR630A05:1",
      "OR630A07:1",
      "OR630A57:1",
      "COB65A07:1",
      "KB848A00:",
      "OR632A07:1",
      "COB67A07:1",
      "COB67A11:1",
      "COB67A61:1",
      "BR849A07:1",
      "BR849A11:1",
      "BR849A61:1",
      "OR633A07:1",
      "OR633A11:1",
      "OR633A61:1",
      "CO090M01:1",
      "CO090M08:1",
      "CO090M14:1",
      "BR090M01:1",
      "BR090M08:1",
      "BR090M14:1",
      "OR067M01:1",
      "OR067M08:1",
      "OR067M14:1",
      "BR031M33:1",
      "BR031M39:1",
      "BR031M41:1",
      "BR031M42:1",
      "BR031M43:1",
      "BR031M44:1",
      "BR031M45:1",
      "BR031M47:1",
      "BR031M49:1",
      "BR031M52:1",
      "BR079M19:1",
      "BR079M33:1",
      "BR079M39:1",
      "BR079M41:1",
      "BR079M42:1",
      "BR079M43:1",
      "BR079M44:1",
      "BR079M45:1",
      "BR079M49:1",
      "BR079M52:1",
      "CO031M01:1",
      "CO031M02:1",
      "CO031M05:1",
      "CO031M06:1",
      "CO031M07:1",
      "CO031M08:1",
      "CO031M09:1",
      "CO031M19:1",
      "CO084M01:1",
      "CO084M02:1",
      "CO084M05:1",
      "CO084M06:1",
      "CO084M07:1",
      "CO084M08:1",
      "CO084M09:1",
      "CO084M19:1",
      "BR030M01:1",
      "BR030M02:1",
      "BR030M05:1",
      "BR030M06:1",
      "BR030M07:1",
      "BR030M08:1",
      "BR030M09:1",
      "BR030M19:1",
      "BR080M01:1",
      "BR080M02:1",
      "BR080M05:1",
      "BR080M06:1",
      "BR080M07:1",
      "BR080M08:1",
      "BR080M19:1",
      "CO048M00:",
      "CO049M00:",
      "CO050M00:",
      "OR040M00:",
      "OR53:",
      "OR54:",
      "WA038M19:1",
      "WA050M04:1",
      "WA050M06:1",
      "WA050M07:1",
      "WA051M01:1",
      "WA051M03:1",
      "WA051M06:1",
      "WA051M14:1",
      "CO095M00:1",
      "BR097M00:1",
      "BR098M00:1",
      "OR073M00:1",
      "CO098M00:",
      "BR102M00:",
      "WA041M00:",
      "WA036999:",
      "AMVJWPC012C07:",
      "AMVJWPC012C09:",
      "AMVJWPC012C14:",
      "AMVJWPC012C25:",
      "AMVJWPC012C35:",
      ];
  
    for (var r of righe) {
      let parts = r.split(":");
      if (parts[1] != "") {
        let p = this.findProduct(parts[0]);
        if (p != null) {
          this.cartManager.addToCart(p, parseInt(parts[1]), false);
        }
      }
    }

    this.cartManager.createOrUpdateOrder();
  }

  findProduct(value: string): any {
    let v = value.toUpperCase();
    let ps = this.cartManager.productList.filter((p) => 
      {
        if (p.code.toUpperCase() == v) {
          return true;
        }
        else {
          return false;
        }
      }
    );
    if (ps && ps.length > 0) {
      return ps[0];
    }
    else {
      alert("Prodotto non trovato: " + value);
      return null;
    }
  }

  
}
