import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogPageComponent } from './catalog-page/catalog-page.component'
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { CartPageComponent } from './cart-page/cart-page.component';
import { LoginComponent } from './login/login.component';
import { OrderHeaderPageComponent } from './order-header-page/order-header-page.component'
import { LoggedInGuard } from './auth/logged-in.guard';
import { HasOrganizzazioneGuard } from './auth/has-organizzazione.guard';
import { RegisterComponent } from './register/register.component';
import { RegisteredComponent } from './registered/registered.component';
import { RegisterExistingComponent } from './register-existing/register-existing.component';

const routes: Routes = [
  { path: 'catalog', component: CatalogPageComponent, canActivate: [LoggedInGuard, HasOrganizzazioneGuard] },
  { path: 'dashboard', component: DashboardPageComponent, canActivate: [LoggedInGuard] },
  { path: 'cart', component: CartPageComponent, canActivate: [LoggedInGuard, HasOrganizzazioneGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register-existing', component: RegisterExistingComponent },
  { path: 'registered', component: RegisteredComponent },
  { path: 'order-header', component: OrderHeaderPageComponent, canActivate: [LoggedInGuard] },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full'}
]

@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes) ]
})
export class AppRoutingModule {}
