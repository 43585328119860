import { Component, OnInit } from '@angular/core';
import { CartManagerService } from '../cart-manager.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Product, Category } from '../app.model';
import { map, startWith } from 'rxjs/operators';
import { CategoriesSelectorComponent } from '../categories-selector/categories-selector.component';
import { ActivatedRoute, Router } from '@angular/router';
import { splitAtColon } from '@angular/compiler/src/util';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-cart-header',
  templateUrl: './cart-header.component.html',
  styleUrls: ['./cart-header.component.css']
})
export class CartHeaderComponent implements OnInit {
  
  searchCtl = new FormControl();
  filteredProducts: Observable<Product[]>;

  constructor( public cartManager: CartManagerService, 
      public categoriesSelector: CategoriesSelectorComponent,
      private router: Router) { 
  }

  ngOnInit() {
    this.filteredProducts = this.searchCtl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.findProducts(value))
      );
  }

  goToCart(): void {
    if (this.cartManager.numRowsPending > 0) {
      alert('Hai prodotti selezionati in attesa di essere aggiunti al carrello');
    }
    else {
      this.router.navigate(['/cart']);
    }
  }

  openSearchResults(): void {
    if (this.searchCtl.value && this.searchCtl.value.length > 0) {
      let products = this.findProducts(this.searchCtl.value);
      var c: Category = this.cartManager.categories[this.cartManager.categories.length - 1];
      if (c.key != '*') {
        c = new Category;
        c.key = '*';
        c.subcategories = [];
        this.cartManager.categories.push(c);
      }
      c.name = 'Risultati ricerca: ' + this.searchCtl.value;
      c.products = products;
      this.cartManager.selectedCategory = c;
      this.cartManager.selectedCategoryIndex = this.cartManager.categories.length - 1;
      this.router.navigate(['/catalog']);
    }
  }

  findProducts(value: string): any {
    let v = value.toUpperCase().split(' ');
    return this.cartManager.productList.filter((p) => 
      {
        for (var v1 of v) {
          if (p.code.indexOf(v1) < 0 && p.name.toUpperCase().indexOf(v1) < 0) {
            return false;
          }
        }
        return true;
      }
    )
  }

  onSelectProduct(event: any, p: Product): void {
    console.log("Selected " + p.name);
    this.cartManager.selectedProduct = p;
    this.searchCtl.setValue('');
  }

  
}
 