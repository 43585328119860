import { Component, OnInit } from '@angular/core';
import { CartManagerService } from '../cart-manager.service';
import { MatExpansionPanel } from '@angular/material';
import { Router } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css'],
  viewProviders: [MatExpansionPanel]
})
export class DashboardPageComponent implements OnInit {

  public displayedColumnsSent: string[] = ['customer', 'orderNumber', 'totalAmount'];
  public displayedColumnsPending: string[] = ['customer', 'orderNumber', 'totalAmount', 'actions'];

  public activePanel: string = 'pendingOrders';

  constructor(public cartManager: CartManagerService, private router: Router) { }

  ngOnInit() {
    this.cartManager.loadDashboardInfo();
  }

  setActivePanel(activePanel: string): void {
    this.activePanel = activePanel;
  }

  openOrder(id: number): void {
    this.cartManager.loadOrder(id).subscribe(
      (data) => {
        this.router.navigate(['/cart'])
      }
    );
  }

  deleteOrder(id: number): void {
    if (confirm("Sicuro di voler cancellare l'ordine?")) {
      this.cartManager.deleteOrder(id).subscribe(
        (data) => this.cartManager.loadDashboardInfo()
      )
    }
  }

}
