import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService, LoginResponse } from '../session.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { routerNgProbeToken } from '@angular/router/src/router_module';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, 
    private sessionService: SessionService,
    private httpService: HttpClient) { }

  username: string;
  password: string;
  message: string;

  ngOnInit() {
  }

  login() : void {

    this.sessionService.login(this.username, this.password)
    .subscribe(
      (data: LoginResponse) => {
        if (data.status == 0) {
          this.message = "";
          this.username = "";
          this.password = "";
          let redirect = this.sessionService.redirectUrl ? this.router.parseUrl(this.sessionService.redirectUrl) : '/dashboard';
 
          // Redirect the user
          this.router.navigateByUrl(redirect)        
        }
        else {
          this.password = "";
          this.message = data.message;
        }
        console.log(data);
      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
      }
    );
    /*
    if(this.username == 'admin' && this.password == 'admin'){
     this.router.navigate(["catalog"]);
    }else {
      alert("Invalid credentials");
    }*/
  }
}
