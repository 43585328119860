import { Component, OnInit, NgModule, Input } from '@angular/core';
import { CategoriesSelectorComponent } from '../categories-selector/categories-selector.component';
import { CartManagerService } from '../cart-manager.service';
import { Product, Category } from '../app.model';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
})
export class ProductDetailsComponent implements OnInit {
    

    @Input()
    public product: Product;
    
    public quantita: number;

  constructor(public categories: CategoriesSelectorComponent, public cartManager: CartManagerService, private router: Router) { 
  }

  ngOnInit() {
      this.quantita = 1;
  }

  closeDetails(): void {
      this.categories.closeDetails()
  }
  
  quantitaPlus(): void {
      this.quantita ++;
  }
  
  quantitaMinus(): void {
      if (this.quantita >= 1)
          this.quantita --;
  }
  
  addToCart() {
      this.cartManager.addToCart(this.product, this.quantita);
      this.quantita = 1;
      this.closeDetails();
  }

}
