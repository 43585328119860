import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Product, Category, CartRow, Organizzazione, Indirizzo, Cart, DashboardInfoResponse } from './app.model';
import { Observable, of, Subscription } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';
import { plainToClass, Type } from 'class-transformer';
import { MatDialog } from '@angular/material';
import { CartDuplicateProductDlgComponent } from './cart-duplicate-product-dlg/cart-duplicate-product-dlg.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CartManagerService {

  cart: Cart;

//  rows: CartRow [] = new Array();

//  notes: string;

  constructor (private httpService: HttpClient, private dialog: MatDialog, private router: Router) { }

  public categories: Category[];
  public productList: Product[] = new Array();
  public keys: string[] = new Array();

  public isLoadingCatalog = false;
  public isLoadingDashboard: boolean;

  public selectedProduct: Product;
  public selectedCategory: Category;
  public selectedCategoryIndex: number;

//  public selectedCustomer: Organizzazione;
//  public selectedDestination: Indirizzo;

  public clienti: Organizzazione[] = null;

  public isSendingOrder = false;

  public lastOrder: Cart;

  public numRowsPending: number = 0;

  public dashboardInfo: DashboardInfoResponse;

//  public dueDate: Date = new Date();

  updateTotals() {
    this.numRowsPending = 0;
    for (let p of this.productList) {
      if (p.quantita && p.quantita > 0) {
        this.numRowsPending++;
      }
    }
  }
 
  
  public loadCatalog () {
    if (this.categories && this.categories.length > 1) {
      return;
    }
    
    var query : {idCliente: any} = {
      idCliente: this.cart.customer.id
    }

    this.isLoadingCatalog = true;
    this.cart.dueDate = new Date();

    this.httpService.get('./kora-ws/rest-api/products', { params: query}).subscribe(
      data => {
        this.productList = [];
        this.categories = data as Category [];   // FILL THE ARRAY WITH DATA.
        console.log(data[0]);

        for (let c of this.categories) {
          this.iterateCategories(c);
        }

        this.productList.sort((a, b) => a.code.localeCompare(b.code));
        this.keys = new Array();

        if (this.categories.length > 0) {
          this.selectedCategory = this.categories[0];
          this.selectedCategoryIndex = 0;
        }

        this.isLoadingCatalog = false;
      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
        this.isLoadingCatalog = false;
      }
    );
  
  }

  public addAllToCart(): void {
    for (let p of this.productList) {
      if (p.quantita && p.quantita > 0) {
        this.addToCart(p, p.quantita, false);
        p.quantita = null;
      }
    }
    this.numRowsPending = 0;
    this.createOrUpdateOrder();
  }

  public clearPending(): void {
    for (let p of this.productList) {
      if (p.quantita) {
        p.quantita = null;
      }
    }
    this.numRowsPending = 0;
  }

  public checkNewCliente(cliente: any): Observable<any>|null {
    return this.httpService.post("./kora-ws/rest-api/customers/checkCliente", cliente);
  }

  public checkClienteEsistente(cliente: any): Observable<any>|null {
    return this.httpService.post("./kora-ws/rest-api/customers/checkClienteEsistente", cliente);
  }

  public loadClienti() {
    if (this.clienti == null) {
      this.httpService.get("./kora-ws/rest-api/customers").subscribe(
        (data: Organizzazione[]) =>
          {
            this.clienti = data;
          },
          (err: HttpErrorResponse) => {
            console.log(err.message);
          }
        );
      }
  }

  searchCustomer(filter: {query: any} = {query: ''}, page = 1): Observable<Organizzazione[]> {
    if (filter.query instanceof Organizzazione) {
      return of([filter.query]);
    }
    else {
      return this.httpService.get<Organizzazione[]>("./kora-ws/rest-api/customers", {
        params: filter
      });
    }
  }

  searchDestination(filter: string) {
    var filteredDestinations: Indirizzo[] = [];

    filter = filter.toUpperCase();

    if (this.cart.customer) {
      for (let i of this.cart.customer.indirizzi) {
        if (i.ragioneSociale2 && i.ragioneSociale2.toUpperCase().includes(filter) ||
            i.indirizzo1 && i.indirizzo1.toUpperCase().includes(filter) ||
            i.indirizzo2 && i.indirizzo2.toUpperCase().includes(filter) ||
            i.localita && i.localita.toUpperCase().includes(filter)) {
              filteredDestinations.push(i);
            }
      }
    }

    return filteredDestinations;
  }

  public iterateCategories(c: Category) {
    for (let p of c.products) {
      if (this.keys.indexOf(p.code) < 0) {
        this.productList.push(p);
        this.keys.push(p.code);
      }
    }

    if (c.subcategories) {

      for (let s of c.subcategories) {
        this.iterateCategories(s);
      }
    }
  }

  public addToCart(p: Product, q: number, update: boolean = true): void {
    var row: CartRow = null;

    for (let r of this.cart.rows) {
      if (r.product.code == p.code) {
        let dialog = this.dialog.open(
          CartDuplicateProductDlgComponent, 
          { 
            data: { 
              dialogText: 'Il prodotto ' + p.name + ' - [' + p.code + '] è già presente nel carrello con quantità ' + r.quantity + '. Vuoi aggiungere altri ' + q + ' pezzi?'
            } 
          }
        );

        dialog.afterClosed().subscribe(
          selection => {
            if (selection == 'YES') {
              r.quantity = r.quantity + q;
              if (update) {
                this.createOrUpdateOrder();
              }
            }
            else if (selection == 'NO') {
              var row: CartRow = new CartRow();
              row.product = p;
              row.quantity = q;
              row.disc1 = p.disc1;
              row.disc2 = p.disc2;
              row.disc3 = p.disc3;
              row.netPrice = p.netPrice;
              
              console.log("added");
              
              this.cart.rows.push(row);
         
              if (update) {
                this.createOrUpdateOrder();
              }
         
            }
          }
        )

        return;
      }
    }


     var row: CartRow = new CartRow();
     row.product = p;
     row.quantity = q;
     row.disc1 = p.disc1;
     row.disc2 = p.disc2;
     row.disc3 = p.disc3;
     row.netPrice = p.netPrice;
     
     console.log("added");
     
     this.cart.rows.push(row);

     if (update) {
       this.createOrUpdateOrder();
     }
  }

  public totalAmount(): number {
    var total: number;

    total = 0.0;

    this.cart.rows.forEach(
      (row) => {
        total = total + row.netPrice * row.quantity;
      }
    )

    return total;
  }

  public totalQuantity(): number {
    var total: number;

    total = 0.0;

    this.cart.rows.forEach(
      (row) => {
        total = total + 1.0 * row.quantity;
      }
    )

    return total;
  }

  createOrUpdateOrder() {
    var order = this.cart;

    this.isSendingOrder = true;

    return this.httpService.post("/kora-ws/rest-api/orders", this.cart) 
      .pipe(
        share()
      )
      .subscribe(
        (data: any) => {
          this.isSendingOrder = false;
          this.cart = plainToClass(Cart, data);
          return this.cart;
        },
        (err: HttpErrorResponse) => {

        }
      );
    
  }

  sendOrder() {
    var order = this.cart;

    this.isSendingOrder = true;

    return this.httpService.post("/kora-ws/rest-api/orders", order, {
      params: { 
        send: 'true',
       }
    })
      .pipe(
        share()
      )
      .subscribe(      (data: any) => {
        this.isSendingOrder = false;
        this.lastOrder = plainToClass(Cart, data);
        this.cart.id = undefined;
        return data;
      },
      (err: HttpErrorResponse) => {
      }
);    
  }

  public registerCustomer(customer: any) {
    return this.httpService.post("/kora-ws/rest-api/customers", customer, {})
      .pipe(share())
      .subscribe(data => this.router.navigate(['/registered']));
  }

  public registerExistingCustomer(customer: any) {
    return this.httpService.post("/kora-ws/rest-api/customers/existing", customer, {})
      .pipe(share())
      .subscribe(data => this.router.navigate(['/registered']));
  }


  public getNumRows(): number {
    if (this.cart && this.cart.rows && this.cart.rows.length > 0) {
      return this.cart.rows.length;
    }
    return 0;
  }

  public hasCustomer(): boolean {
    if (this.cart && this.cart.customer) {
      return true;
    }
    return false;
  }
  
  public hasDestination(): boolean {
    if (this.cart && this.cart.destination) {
      return true;
    }
    return false;
  }

  public loadDashboardInfo() {
    this.isLoadingDashboard = true;
    return this.httpService.get('./kora-ws/rest-api/orders').subscribe(
        (data) => {
          this.dashboardInfo = plainToClass(DashboardInfoResponse, data);
          this.isLoadingDashboard = false;
        },
        (err: HttpErrorResponse) => {
          console.log (err.message);
          this.isLoadingDashboard = false;
        }
    );
  }

  loadOrder(id: number): Observable<Object> {
    this.isLoadingDashboard = true;
    var req = this.httpService.get("./kora-ws/rest-api/orders/" + id).pipe(
      share()
    );
    req.subscribe(
      (data) => {
        this.cart = plainToClass(Cart, data);
        this.isLoadingDashboard = false;
      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
        this.isLoadingDashboard = false;
      }
    );
    return req;
  }

  deleteOrder(id: number): Observable<Object> {
    this.isLoadingDashboard = true;
    var req = this.httpService.delete("./kora-ws/rest-api/orders/" + id).pipe(
      share()
    );
    req.subscribe(
      (data) => this.isLoadingDashboard = false,
      (err: HttpErrorResponse) => {
        console.log(err.message);
        this.isLoadingDashboard = false;
        alert('Errore di comunicazione col server: ' + err.message);
      }
    );
    return req;


  }
 
}
