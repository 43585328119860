import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Router } from '@angular/router';

export class LoginResponse {
  loginToken: object;
  message: string;
  status: number;
  Utente: object;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  loginStatus: LoginResponse;

  redirectUrl: string;



  constructor(private httpService: HttpClient, private router: Router) { }

  login(username: string, password: string): Observable<LoginResponse> {

    var o: Observable<any>;

    this.loginStatus = null;

    o = this.httpService.post("/kora-ws/rest-api/login", {
      "userid": username,
      "password": password 
    }).pipe(
      share()
    );

    o.subscribe(
      (data: LoginResponse) => {
        this.loginStatus = data;
        let redirect = this.redirectUrl ? this.router.parseUrl(this.redirectUrl) : '/dashboard';
 
        // Redirect the user
        this.router.navigateByUrl(redirect)   },
      (err: HttpErrorResponse) => {
        console.log (err.message);
        this.loginStatus = null;
      }
    );

    return o;


  }
}
