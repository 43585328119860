import { Component, OnInit, ViewChild } from '@angular/core';
import { CartManagerService } from '../cart-manager.service';
import { Organizzazione, Indirizzo, Cart } from '../app.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { debounceTime, switchMap, tap, finalize, filter } from 'rxjs/operators';
import { SessionService } from '../session.service';
import { MatStepper } from '@angular/material';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { of } from 'rxjs';

@Component({
  selector: 'app-order-header-page',
  templateUrl: './order-header-page.component.html',
  styleUrls: ['./order-header-page.component.css']
})
export class OrderHeaderPageComponent implements OnInit {

  isLoadingCustomer = false;
  orderHeaderForm: FormGroup;
  filteredCustomers: Organizzazione[] = [];
  filteredDestinations: Indirizzo[] = [];
  @ViewChild('stepper') private stepper: MatStepper;
  
  constructor(private fb: FormBuilder, public cartManager: CartManagerService) { }

  ngOnInit() {

    this.cartManager.cart = new Cart();

    this.orderHeaderForm = this.fb.group(
      {
        customerInput: new FormControl(null, Validators.required),
        destinationInput: new FormControl(null, Validators.required)
      }
    );

    this.orderHeaderForm
      .get('customerInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoadingCustomer = true),        
        switchMap((value) => {
          if (value instanceof Object) {
            if (!this.cartManager.cart) {
              this.cartManager.cart = new Cart();
            }
            this.cartManager.cart.customer = value;
            this.cartManager.categories = null;
            this.cartManager.productList = new Array();
            if (value.indirizzi.length == 1) {
              this.cartManager.cart.destination = value.indirizzi[0];
              this.orderHeaderForm.get('destinationInput').setValue(this.cartManager.cart.destination);
            }
            this.stepper.selectedIndex ++;
            return of([value]);
          }
          else {
            return this.cartManager.searchCustomer({query: value}, 1)
            .pipe(
              finalize(() => this.isLoadingCustomer = false)
            )}
          }
        )
      )
      .subscribe(customers => {
        var o: Organizzazione;
        this.filteredCustomers = [];
        customers.map((o: Organizzazione) => this.filteredCustomers.push(o));
        return this.filteredCustomers;
      });

    this.orderHeaderForm
      .get('destinationInput')
      .valueChanges
      .pipe(
        switchMap((value) => {
          if (value instanceof Object) {
            this.cartManager.cart.destination = value;
            return of([value]);
          }
          else {
            return of(this.cartManager.searchDestination(value));
          }
        })
      )
      .subscribe(destinations => {
        this.filteredDestinations = [];
        destinations.map((i: Indirizzo) => this.filteredDestinations.push(i));
        return this.filteredDestinations;
      });

  
  }

  decodeCustomer(customer: Organizzazione) {
    if (customer) return "[" + customer.codice + "] " + customer.ragioneSociale1;
  }

  decodeDestination(destination: Indirizzo) {
    if (destination) {
      if (destination.ragioneSociale2) {
        return destination.ragioneSociale2 + " - " + destination.indirizzo1 + " - " + destination.localita;
      }
      else {
        return destination.indirizzo1 + ' - ' + destination.localita
      }
    }
  }

  customerSelected(customer: Organizzazione) {
    console.log(customer.ragioneSociale1);
    if (!this.cartManager.cart) {
      this.cartManager.cart = new Cart();
    }
    this.cartManager.cart.customer = customer;
    this.cartManager.cart.destination = customer.indirizzi[0];
    this.stepper.selectedIndex += 1;
  }

  getDestinations() {
    if (this.cartManager.cart && this.cartManager.cart.customer) {
      return this.cartManager.cart.customer.indirizzi;
    }
    else {
      return [];
    }
  }

  destinationSelected(destination: Indirizzo) {
    this.cartManager.cart.destination = destination;
  }
}
